<template>
  <div>
    <ModalBase :theme="'dark'" v-model="toggled">
      <template>
        <div class="c-video-modal">
          <div v-if="selectedvideo" class="component c-video-modal__media">
            <iframe
              v-if="
                selectedvideo.component_type === 'All Access' ||
                selectedvideo.type === 'allaccess'
              "
              id="modalIframe"
              :src="
                selectedvideo
                  ? `${window.VUE_APP_SIDEARM_INSTANCE_BASE_URL}showcase/embed.aspx?Archive=` +
                    (selectedvideo.type === 'allaccess'
                      ? selectedvideo.data.id
                      : selectedvideo.id)
                  : 'about:blank'
              "
              frameborder="no"
              allowfullscreen="yes"
              marginheight="0"
              marginwidth="0"
              style="width: 100%; height: 100%"
            ></iframe>
            <youtube
              v-if="
                selectedvideo.component_type === 'YouTube' ||
                selectedvideo.type === 'youtube'
              "
              :video-id="videoId"
              :player-width="'100%'"
              :player-height="'100%'"
              @ready="ready"
              @change="change"
            ></youtube>
          </div>
        </div>
      </template>
    </ModalBase>
  </div>
</template>
<script>
import ModalBase from "@/components/modal/ModalBase";

export default {
  name: "VideoModal",

  components: {
    ModalBase,
  },
  props: ["value", "selectedvideo"],
  data: () => ({
    videoId: null,
  }),
  computed: {
    toggled: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ready(event) {
      if (this.selectedvideo.data) {
        this.videoId = this.selectedvideo.data.id;
      } else {
        this.videoId = this.selectedvideo.id;
      }
      this.player = event.target;
    },
    stop() {
      this.player.stopVideo();
    },
    change() {
      if (this.selectedvideo.data) {
        this.videoId = this.selectedvideo.data.id;
      } else {
        this.videoId = this.selectedvideo.id;
      }
    },
    play() {
      this.player.playVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
  watch: {
    toggled: function (toggled) {
      console.log(toggled);
      this.change();
      if (!toggled) {
        this.pause();
      }
      if (toggled) {
        this.play();
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.c-video-modal {
  width: 85%;
  max-width: 1280px;
  &__media {
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    display: flex;
    > div {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: none;
    }
  }
  iframe,
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
}
</style>
