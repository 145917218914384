var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalBase',{attrs:{"theme":'dark'},model:{value:(_vm.toggled),callback:function ($$v) {_vm.toggled=$$v},expression:"toggled"}},[[_c('div',{staticClass:"c-video-modal"},[(_vm.selectedvideo)?_c('div',{staticClass:"component c-video-modal__media"},[(
              _vm.selectedvideo.component_type === 'All Access' ||
              _vm.selectedvideo.type === 'allaccess'
            )?_c('iframe',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"modalIframe","src":_vm.selectedvideo
                ? (_vm.window.VUE_APP_SIDEARM_INSTANCE_BASE_URL) + "showcase/embed.aspx?Archive=" +
                  (_vm.selectedvideo.type === 'allaccess'
                    ? _vm.selectedvideo.data.id
                    : _vm.selectedvideo.id)
                : 'about:blank',"frameborder":"no","allowfullscreen":"yes","marginheight":"0","marginwidth":"0"}}):_vm._e(),(
              _vm.selectedvideo.component_type === 'YouTube' ||
              _vm.selectedvideo.type === 'youtube'
            )?_c('youtube',{attrs:{"video-id":_vm.videoId,"player-width":'100%',"player-height":'100%'},on:{"ready":_vm.ready,"change":_vm.change}}):_vm._e()],1):_vm._e()])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }